import _ from 'lodash';
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/Header';
import ProductGrid from '../components/products/ProductGrid';
import PropTypes from 'prop-types';

const renderHtmlDescription = (html, frontmatter) => {
  return (
    <div className='category-description grid-x'>
      {frontmatter.alert_text && (
        <div className='cell small-12 callout primary' style={{ marginBottom: '25px' }}>
          <p>
            <strong>{frontmatter.alert_heading}</strong>
          </p>
          <p>{frontmatter.alert_text}</p>
        </div>
      )}
      <div
        className='category-description__description-text cell small-12 medium-8'
        dangerouslySetInnerHTML={{ __html: html }}
      />
      {frontmatter.read_more_heading && (
        <div className='category-description__read-more-links cell small-12 medium-4'>
          <h3>{frontmatter.read_more_heading}</h3>
          <ul>
            {frontmatter.read_more_links.map((link, i) => (
              <li key={i}>
                <a href={link.link}>{link.title}</a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const ProductCategory = ({ data, pageContext }) => {
  const [sortBy, setSortBy] = useState('BotanicalName');

  if (data.markdownRemark === null) {
    return null;
  }

  const { Tag } = pageContext;
  const { html, frontmatter } = data.markdownRemark;
  const staticProducts = _.filter(
    data.productsapi.GetProductsWithTagPlugtrays.products,
    ({ DisplayPlugtrays }) => DisplayPlugtrays
  );
  const headerGridClass = frontmatter ? 'small-12' : 'small-12 medium-8';
  const htmlDescription = renderHtmlDescription(html, frontmatter);

  // filter out the 'plug trays A-F` categories to avoid SEO weirdness
  const SEOTitle = Tag.indexOf('Plug Trays') === 0 ? 'Plug Trays' : Tag;

  const sortByChanged = (e) => {
    const value = e.target.value;
    setSortBy(value);
  };
  const sortByRendered = (
    <>
      <label htmlFor='product-grid--sort-box'>Sort by:</label>
      <select id='product-grid--sort-box' onChange={sortByChanged} value={sortBy}>
        <option value='BotanicalName'>Botanic name</option>
        <option value='CommonName'>Common name</option>
      </select>
    </>
  );

  return (
    <Layout>
      <SEO
        title={`Organic ${SEOTitle}`}
        keywords={['organic', SEOTitle]}
        description={`Certified organic ${SEOTitle} for sale at Mountain Valley Growers`}
      />
      <Header />
      <div
        className='grid-x grid-margin-x grid-margin-y grid-padding-x grid-padding-y'
        itemScope
        itemType='http://schema.org/ItemList'
      >
        <div className={`cell ${headerGridClass}`}>
          <h2 itemProp='name'>{Tag}</h2>
          {!frontmatter && <span itemProp='numberOfItems'>({staticProducts.length} plants)</span>}
          {htmlDescription}
        </div>
        {frontmatter && (
          <div className='cell small-12 medium-8'>
            <h3 className='product-grid__title'>{Tag}</h3>
            <span itemProp='numberOfItems'>({staticProducts.length} plants)</span>
          </div>
        )}
        <div className='cell small-12 medium-4 product-grid--sort'>{sortByRendered}</div>
      </div>
      <div className='grid-x grid-margin-x grid-margin-y grid-padding-x grid-padding-y'>
        <ProductGrid Tag={Tag} staticProducts={staticProducts} sortBy={sortBy} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($Tag: String!) {
    markdownRemark(frontmatter: { tags: { in: [$Tag] } }) {
      html
      frontmatter {
        alert_heading
        alert_text
        read_more_heading
        read_more_links {
          title
          link
        }
      }
    }
    productsapi {
      GetProductsWithTagPlugtrays(Tag: $Tag) {
        products {
          CommonName
          BotanicalName
          Id
          Name
          NamePlugtrays
          Description
          DescriptionPlugtrays
          MVGPath
          Images
          Height
          Hardiness
          FlowerColor
          Characteristics
          Uses
          Tags
          TagsPlugtrays
          Slug
          DisplayPlugtrays
          ImageHashes
        }
      }
    }
  }
`;

ProductCategory.displayName = 'ProductCategoryTemplate';

ProductCategory.defaultProps = {
  pageContext: undefined,
  data: undefined,
};

ProductCategory.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default ProductCategory;
